import React from 'react';
import { Container } from 'emotion-flex';
import { FormattedMessage } from 'react-intl';
import Illustration from '../assets/images/undraw_time_management_30iu.svg';

import SEO from '../components/SEO';
import Link from '../components/Link';
import Button from '../components/Button';
import { withLayout } from '../components/Layout';
import { useBrowserLocale } from '../hooks';

const ButtonLink = Button.withComponent(Link);

const EmailChangeExpired = () => {
  useBrowserLocale();

  return (
    <>
      <SEO title="Reset token expired" />
      <Container>
        <div css={{ padding: '64px 0', textAlign: 'center' }}>
          <img src={Illustration} alt="Token expired" css={{ maxWidth: 480 }} />
          <h1>
            <FormattedMessage
              id="pages.email.failedToConfirmEmail"
              defaultMessage="Failed to confirm email"
            />
          </h1>
          <p>
            <FormattedMessage
              id="pages.email.resetTokenExpried"
              defaultMessage="The reset token expired. You have to confirm email within 10 minutes."
            />
          </p>
          <ButtonLink to="https://cara.app.link/hoGy2zXkdy">
            <FormattedMessage
              id="pages.email.continueToApp"
              defaultMessage="Continue to App"
            />
          </ButtonLink>
        </div>
      </Container>
    </>
  );
};

export default withLayout(EmailChangeExpired, {
  isNewLayout: true,
  hideLanguageSwap: true,
});
