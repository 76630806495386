import styled from '@emotion/styled';
import presets from '../styles/presets';

interface Props {
  block?: boolean;
  disabled?: boolean;
}

const Button = styled<'button', Props>('button')`
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  margin: 0;
  padding: 15px 30px;
  width: 100%;
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  line-height: 1.2;
  background-color: ${({ theme, disabled }) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore https://github.com/emotion-js/emotion/issues/802
    disabled ? '#b9c9c8' : theme.colors.primary};
  border: 2px solid transparent;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  outline: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  box-shadow: ${({ theme }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore https://github.com/emotion-js/emotion/issues/802
    return theme.boxShadow;
  }};

  @media only screen and (min-width: 300px) {
    min-width: 300px;
  }

  ${presets.md} {
    width: initial;
  }
`;

Button.displayName = 'Button';

export default Button;
